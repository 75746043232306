import $ from "jquery";

const backToTop = {
    threshold: 500,

    scrolled() {
        let scrollTop = $(window).scrollTop();
        if (scrollTop > this.threshold) {
            $(".back_to_top").addClass("show");
        } else {
            $(".back_to_top").removeClass("show");
        }
    },
    clicked(e) {
        e.preventDefault();
        $("html,body").animate(
            {
                scrollTop: 0,
            },
            700,
        );
    },
    init() {
        $(window).on("scroll", () => {
            backToTop.scrolled();
        });
        $(".back_to_top").on("click", (e) => {
            backToTop.clicked(e);
        });
        backToTop.scrolled();
    },
};
window.backToTop = backToTop;
$(() => {
    if ($(".back_to_top").length) {
        backToTop.init();
    }
});
