import $ from "jquery";
import winCollector from "../helpers/win-collector";

const isOn = "1";
const syncKey = "sync-ftvs";
const lpFtvsKey = "lp-ftvs";
const requestFtvsKey = "request-ftvs";
const ftvsModal = document.getElementById("lux-modal-ftvs");

/**
 * Sets the FTVS status and optionally syncs it across tabs.
 * @param {boolean} [sync=true] - Send status to other tabs.
 * @returns {void}
 */
function setFTVS(sync = true) {
    sessionStorage.setItem(lpFtvsKey, isOn);
    // Send status to other tabs
    if (sync) {
        localStorage.setItem(syncKey, isOn);
        localStorage.removeItem(syncKey);
    }

    if (ftvsModal !== null && ftvsModal.classList.contains("active")) {
        ftvsModal.classList.remove("active");
        winCollector();
        setTimeout(() => {
            $("body").removeClass("show-ftvs");
        }, 100);
    }
}

/**
 * Retrieves the value of a URL parameter.
 * @param {string} sParam - The name of the URL parameter.
 * @returns {(string|boolean|undefined)} The value of the parameter, or `true` if it exists without a value, or `undefined` if it doesn't exist.
 */
function getUrlParameter(sParam) {
    const sPageURL = window.location.search.substring(1);
    const sURLVariables = sPageURL.split("&");

    for (let i = 0; i < sURLVariables.length; i++) {
        const sParameterName = sURLVariables[i].split("=");

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined
                ? true
                : decodeURIComponent(sParameterName[1]);
        }
    }

    return undefined;
}

/**
 * Handles storage events.
 * @param {StorageEvent} event - The storage event.
 * @returns {void}
 */
function handleStorageEvent(event) {
    const ftvsStatus = sessionStorage.getItem(lpFtvsKey);

    if (event.key === requestFtvsKey && ftvsStatus === isOn) {
        // Some tab asked for the ftvs value
        localStorage.setItem(syncKey, isOn);
        localStorage.removeItem(syncKey);
    } else if (
        event.key === syncKey &&
        event.newValue === isOn &&
        ftvsStatus !== isOn
    ) {
        // Another tab sent the ftvs value
        setFTVS(false);
    }
}

/**
 * Initializes the FTVS functionality.
 * @returns {void}
 */
function initializeFTVS() {
    if (getUrlParameter("ftvs") === isOn) {
        setFTVS();
        return;
    }

    if (sessionStorage.getItem(lpFtvsKey) !== isOn) {
        // Ask other tabs for ftvs value
        localStorage.setItem(requestFtvsKey, Date.now().toString());
    }

    $(".first-time-visitor a, .first-time-visitor button.btn").on(
        "click",
        () => {
            setFTVS();
        },
    );

    setTimeout(() => {
        if (ftvsModal !== null && sessionStorage.getItem(lpFtvsKey) !== isOn) {
            ftvsModal.classList.add("active");
            if (ftvsModal.classList.contains("active")) {
                $("body").addClass("show-ftvs");
            }
        }
    }, 400);
}

window.addEventListener("storage", handleStorageEvent);

initializeFTVS();
