import $ from "./leaked-jquery";
import "bootstrap";
import "jquery-unveil";

const misc = {
    lazyload() {
        $("img.lazy, source.lazy, source.lazy-hidden, video.lazy").unveil(
            200,
            function () {
                $(this).on("load", function () {
                    this.style.opacity = 1;
                });
            },
        );
        $("img.transparent").unveil(200, function () {
            $(this).on("load", function () {
                this.style.opacity = 0.2;
            });
        });
    },
    fixIfBodyIsSmallThanViewport() {
        const body = document.body;
        const html = document.documentElement;
        const height = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight,
        );
        const vh = Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0,
        );
        if (height < vh) {
            $("html").css(
                "background-color",
                $("footer .footer_wrap").css("background-color"),
            );
        }
    },
    initializeTooltips() {
        $('[data-toggle="tooltip"]').tooltip();
    },
    lazyLoadOnTabChange() {
        $('a[data-toggle="tab"]').on("shown.bs.tab", (event) => {
            $(window).trigger("scroll.unveil"); //trigger lazyLoad of any images that was hidden.
        });
    },
    showMore: function () {
        var next_page_url = $(".btn-see-more-blogposts").data("url");

        $(".blog-posts-wrapper")
            .append(
                '<div class="logo-loader"><div class="logo-spinner"></div></div>',
            )
            .addClass("loading");

        $.getJSON(next_page_url, function (data, item) {
            $(".btn-wrapper").remove();
            $(".logo-loader").remove();
            $(".blog-posts-wrapper").append(data).removeClass("loading");
        });
    },
};
window.misc = misc;

$(() => {
    misc.lazyload();
    misc.initializeTooltips();
    misc.fixIfBodyIsSmallThanViewport();
    misc.lazyLoadOnTabChange();
});
